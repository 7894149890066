<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }">
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div class="col-md-11">
              <h1>Allowance Audit Trail</h1>
            </div>
            <div class="col-md-1">
            </div>
          </div>
          <v-text-field v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details></v-text-field>
          <v-data-table :loading="!UnionJobtitleSalaries.length"
                        loading-text="Fetching records... Please wait"
                        :headers="headers"
                        :items="UnionJobtitleSalaries"
                        :search="search">
          </v-data-table>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
import {
  unionMemberService
} from '@/services'
export default {
  data () {
    return {
      alertMessage: '',
      dialog: false,
      alert: false,
      isLoading: false,
      alertType: '',
      search: '',
      UnionJobtitleSalaries: [],
      headers: [
        {
          text: 'Union Membership',
          value: 'unionMembership.name'
        },
        {
          text: 'Salary Component',
          value: 'salaryComponent.description'
        },
        {
          text: 'Old Amount/Rate',
          value: 'previousAmount'
        },
        {
          text: 'Amount/Rate',
          value: 'amount'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'Modified By',
          value: 'addedBy'
        },
        {
          text: 'Modified Date',
          value: 'lastModifiedDate'
        }
      ],
      unionJobtitle: [],
      unionMember: '',
      formData: {
        values: []
      },
      selected: [],
      id: ''
    }
  },
  computed: {

  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    }
  },
  mounted () {
    unionMemberService.getAudit().then(result => {
      this.UnionJobtitleSalaries = result.data
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer > > > tbody tr :hover {
    cursor: pointer;
  }

  .hint {
    font-size: 13px;
    margin-top: 1px;
  }
</style>
